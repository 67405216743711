import React, { useState, createContext, useContext, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  } catch (error) {
    return true; // Assume expired or invalid if error occurs
  }
};

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token && !isTokenExpired(token)) {
      setIsAuthenticated(true);
    }
    setIsAuthenticating(false);
  }, []);

  const login = (access, refresh) => {
    // Set token in localStorage and update state
    localStorage.setItem("accessToken", access);
    localStorage.setItem("refreshToken", refresh);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`;
    setIsAuthenticated(true);
  };

  const logout = () => {
    // Remove token from localStorage and update state
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, isAuthenticating, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}
