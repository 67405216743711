import React, { useState } from "react";

function TaskList() {
  const [tasks, setTasks] = useState([]);
  const fetchData = async (url) => {
    const response = await fetch(url);
    const data = await response.json();
    setTasks([...tasks, ...data]);
  };

  return (
    <div>
      <button
        onClick={
          () =>
            fetchData("https://dawn-ai-407400.uw.r.appspot.com/myapi/tasks/")
          // fetchData("http://localhost:8000/myapi/tasks/")
        }
        disabled={tasks.length >= 5}
      >
        Click me to view task list!
      </button>
      {tasks.length > 0 && (
        <ul>
          {tasks.map((task, i) => (
            <li key={i}>{task.title}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default TaskList;
