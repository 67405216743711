import React, { useEffect } from "react";
import { useAuth } from "./AuthProvider";
import { useNavigate } from "react-router-dom";

function ProtectedRoute({ children }) {
  const { isAuthenticated, isAuthenticating } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticating) {
      return;
    }

    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, isAuthenticating, navigate]);

  return <>{children}</>;
}

export default ProtectedRoute;
