import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://dawn-ai-407400.uw.r.appspot.com/myapi/token/",
        // "http://localhost:8000/myapi/token/",
        {
          username,
          password,
        }
      );
      // Handle login success (e.g., redirect, store user data)
      console.log(response.data);
      const { access, refresh } = response.data;
      login(access, refresh);
      navigate("/tasklist");
    } catch (error) {
      console.error(error);
      // Handle login failure
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <button type="submit">Login</button>
    </form>
  );
}

export default LoginForm;
