import logo from "./logo.svg";
import "./App.css";
import React from "react";
import LoginForm from "./LoginForm";
import TaskList from "./TaskList";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import { AuthProvider } from "./AuthProvider";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Dawn AI coming soon</p>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginForm />} />
              <Route
                path="/tasklist"
                element={
                  <ProtectedRoute>
                    <TaskList /> 
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Router>
        </AuthProvider>
      </header>
    </div>
  );
}

export default App;
